import React, { FC } from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

import { ServiceHero, ServiceHeroProps, ServiceSection } from "components";
import {
  Contact,
  Experts,
  Processes,
  SEO,
  SEOProps,
  ServiceHeaderMenu,
} from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";

import { ServiceTemplateProps } from "./types";

export const ServiceTemplate: FC<ServiceTemplateProps> = ({
  data: {
    service: {
      body,
      excerpt,
      frontmatter: {
        brand,
        // dateCreated,
        // dateUpdated,
        description,
        hero,
        header,
        image,
        layout,
        main,
        mdx,
        seo,
        showExperts = true,
        showFooter = true,
        showHeader = true,
        showProcesses = true,
        // tags,
        title,
        contact,
      },
    },
  },
  location,
  // pageContext: { next, prev },
}) => (
  <>
    <SEO
      title={seo?.title || title}
      description={
        seo?.description
          ? seo?.description
          : description
          ? description
          : excerpt
      }
      image={image && getSrc(image.childImageSharp.gatsbyImageData)}
      url={location?.href}
      {...(seo as SEOProps)}
    />
    <DefaultLayout
      brand={{ color: "black", linkTo: "/#services", ...brand }}
      header={{
        color: "secondary",
        right: <ServiceHeaderMenu {...header?.menu} />,
        ...header,
      }}
      location={location}
      main={main}
      {...(layout as DefaultLayoutProps)}
    >
      <>
        {showHeader && (
          <ServiceHero
            {...(hero as ServiceHeroProps)}
            caption={{ heading: title, ...hero?.caption }}
          />
        )}
        <ServiceSection body={body} image={image} mdx={mdx} />
        {showProcesses && <Processes />}
        {showExperts && <Experts />}
        {showFooter && <Contact location={location} {...contact} />}
      </>
    </DefaultLayout>
  </>
);

export const serviceBySlugQuery = graphql`
  query ServiceBySlug($slug: String!) {
    service: mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        dateCreated(formatString: "MMMM DD, YYYY")
        dateUpdated(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        tags
        title
      }
    }
  }
`;

export default ServiceTemplate;
